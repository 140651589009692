@import "../../styles/_partials/mixins";

.about__section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include mobile {
    text-align: center;
  }

  &--title {
    font-size: 3rem;
    color: $white;

    @include mobile {
      font-size: 2rem;
    }
  }
}

.about__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &--text {
    color: $white;
    font-size: 24px;
    word-spacing: 1.5px;
    letter-spacing: 1.5px;
    padding: 12px;
    text-align: center;
    text-shadow: 0 2px 5px rgb(0, 0, 0);

    @include mobile {
      font-size: 16px;
      word-spacing: 0.8px;
      letter-spacing: 0.8px;
      padding: 6px;
    }
  }
}
