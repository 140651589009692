//Colours
$dark-gray: #333;
$black: #000;
$light-gray: #f4f4f4;
$white: #fff;
$trans-gray: rgba(36, 34, 36, 0.6);

//Screen Sizes
$mobile: 767px;
$tablet-min: 768px;
$tablet-max: 1279px;
$desktop: 1280px;

//Screen Sizes Media Queries
@mixin mobile {
  @media only screen and (max-width: $mobile) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: $desktop) {
    @content;
  }
}

//Shadows
$white-box-shadow: 0 2px 5px rgb(255, 255, 255);
$white-text-shadow: 1px 1px 1px $white;

//Font Faces
@font-face {
  font-family: "Poppins-black";
  src: url("../../assets/fonts/poppins/Poppins-Black.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins-black-italic";
  src: url("../../assets/fonts/poppins/Poppins-BlackItalic.ttf")
    format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Poppins-bold";
  src: url("../../assets/fonts/poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Poppins-bold-italic";
  src: url("../../assets/fonts/poppins/Poppins-BoldItalic.ttf")
    format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Poppins-extraBold";
  src: url("../../assets/fonts/poppins/Poppins-ExtraBold.ttf")
    format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Poppins-extraLight-italic";
  src: url("../../assets/fonts/poppins/Poppins-ExtraLightItalic.ttf")
    format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Poppins-italic";
  src: url("../../assets/fonts/poppins/Poppins-Italic.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins-light";
  src: url("../../assets/fonts/poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Poppins-light-italic";
  src: url("../../assets/fonts/poppins/Poppins-LightItalic.ttf")
    format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Poppins-medium";
  src: url("../../assets/fonts/poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins-medium-italic";
  src: url("../../assets/fonts/poppins/Poppins-MediumItalic.ttf")
    format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins-regular";
  src: url("../../assets/fonts/poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins-semiBold";
  src: url("../../assets/fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Poppins-semiBold-italic";
  src: url("../../assets/fonts/poppins/Poppins-SemiBoldItalic.ttf")
    format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Poppins-thin";
  src: url("../../assets/fonts/poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Poppins-thin-italic";
  src: url("../../assets/fonts/poppins/Poppins-ThinItalic.ttf")
    format("truetype");
  font-weight: 100;
}

//Padding

//Margin

//Border

//Border Radius

//Box Shadow

//Transition

//Flexbox

//Grid

//Positioning
