@import "../../styles/_partials/mixins";

.home {
  &__section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media only screen and (max-width: $mobile) {
      text-align: center;
    }
  }

  &__grid {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      position: relative;
      margin: 2rem;
    }
  }

  &__title {
    font-size: 3rem;
    color: $white;
  }
  &__subtitle {
    font-size: 1.5rem;
    color: $white;
  }
  &__btn {
    text-decoration: none;
    border-radius: 4px;
    margin-top: 2rem;
    padding: 1rem 2rem;
    border: none;
    text-shadow: $white-text-shadow;
    background-color: $white;
    color: $black;

    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: #000;
      color: $white;
    }
  }
}
