@import "./_partials/mixins";
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  background-image: url("../assets/images/portbg.jpg");
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.page__container {
  position: relative;
  display: flex;
  flex-direction: column;
}
