@import "../../styles/_partials/mixins";

.content__container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.projects__section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;
  padding: 36px;
  @media only screen and (max-width: $mobile) {
    text-align: center;
    padding: 24px;
  }

  &--title {
    font-size: 3rem;
    color: $white;

    @include mobile {
      font-size: 2rem;
    }
  }
}
