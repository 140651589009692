@import "../../styles/_partials/mixins";

.form {
  &__container {
    box-sizing: border-box;
    background-color: $trans-gray;
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include mobile {
      padding: 12px;
      width: 100%;
      align-items: normal;
    }

    &-name {
      label {
        color: $white;
        float: left;
        padding: 1rem 0rem 1rem 0rem;
        font-size: 24px;

        @media only screen and (max-width: $mobile) {
          font-size: 18px;
        }
      }
      input {
        box-sizing: border-box;
        padding: 1rem;
        border-radius: 4px;
        border: none;
        width: 24rem;

        @include mobile {
          width: 100%;
        }
      }
    }

    &-email {
      label {
        color: $white;
        float: left;
        padding: 1rem 0rem 1rem 0rem;
        font-size: 24px;

        @include mobile {
          font-size: 18px;
        }
      }
      input {
        box-sizing: border-box;
        padding: 1rem;
        border-radius: 4px;
        border: none;
        width: 24rem;

        @include mobile {
          width: 100%;
        }
      }
    }

    &-message {
      label {
        color: $white;
        float: left;
        padding: 1rem 0rem 1rem 0rem;
        font-size: 24px;
        @media only screen and (max-width: $mobile) {
          font-size: 18px;
        }
      }
      textarea {
        box-sizing: border-box;
        padding: 1rem;
        border-radius: 4px;
        border: none;
        width: inherit;
        resize: none;
        width: 24rem;
        @media only screen and (max-width: $mobile) {
          width: 100%;
        }
      }
    }

    &-btn {
      button {
        text-decoration: none;
        border-radius: 4px;
        margin-top: 2rem;
        padding: 1rem 2rem;
        border: none;
        text-shadow: $white-text-shadow;
        background-color: $white;
        color: $black;

        font-size: 1rem;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          background-color: #000;
          color: $white;
          transition: all 0.3s;
          text-shadow: $white-box-shadow;
        }
      }
    }
  }
}

.contact {
  &__section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    text-align: center;

    &-title {
      font-size: 3rem;
      color: $white;
      @include mobile {
        font-size: 2rem;
      }
    }
  }
}
