@import "../../styles/_partials/mixins";

.nav {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: $trans-gray;
    box-shadow: 0 2px 5px rgb(255, 255, 255);

    @include mobile {
      flex-direction: column;
      padding: 48px;
    }
  }

  &__links-home {
    text-decoration: none;
    padding: 1.5rem;
    color: $white;

    &:hover {
      color: $black;
      text-decoration: underline;
      text-shadow: 1px 1px 1px $white;
    }
  }

  &__links {
    text-decoration: none;
    padding: 1.5rem;
    color: $white;

    &:hover {
      color: $black;
      outline: $white;
      border: 1px solid $white;
      text-shadow: 1px 1px 1px $white;
      transition: all 0.3s;
    }
  }
}
