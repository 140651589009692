@import "../../styles/_partials/mixins";

.footer {
  &__container {
    background-color: $trans-gray;
    bottom: 0;
    right: 0;
    left: 0;
  }
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.5rem;
      flex-direction: column;
    }
  }

  &__title {
    color: $white;

    &-subtext {
      color: $white;
      font-size: 1rem;
      word-spacing: 1.5px;
      text-align: center;
    }
  }

  &__icon {
    fill: $white;
    padding: 8px;
  }

  &__copyright {
    color: $white;
    text-align: center;

    &-link {
      text-decoration: none;
      color: $white;
      transition: all 0.3s;

      &:hover {
        color: $black;
        text-shadow: $white-box-shadow;
        text-decoration: underline;
      }
    }
  }

  @include mobile {
    &__grid {
      display: flex;
      flex-direction: column;
    }
  }
}
