@import "../../styles/_partials/mixins";

.card {
  &__container {
    background-color: $trans-gray;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.4);
    transition: all 0.3s;
    margin-bottom: 36px;

    &--url {
      text-decoration: none;
    }

    &--name {
      font-size: 2rem;
      color: $white;

      @include mobile {
        font-size: 1.5rem;
      }
    }

    &--frame {
      font-size: 0.9rem;
      color: $white;
      font-style: italic;

      @include mobile {
        font-size: 0.8rem;
      }
    }

    &--status {
      font-size: 1.2rem;
      color: green;

      @include mobile {
        font-size: 1rem;
      }
    }
  }
}
